<template>
    <div class="settings-modal">
        <v-app id="settings" class="dynamic">
            <v-row>
                <v-col>
                    <v-text-field
                        filled
                        label="Public Script Title"
                        v-model="publicName"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <select ref="languages" @change="languageChanged">
                        <option v-for="[key, label] in Object.entries(languages)" :value="key" :key="key" :selected="options.language === key">
                            {{ label }} ({{ key }})
                        </option>
                    </select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn class="white--text" :color="color.blue" @click="openDataPointsModal">
                        <FaIcon icon="cog" />
                        External data
                    </v-btn>
                    <v-btn class="white--text" :color="color.green" @click="exportScript">
                        <FaIcon icon="file-export" />
                        Export Script to file
                    </v-btn>
                </v-col>
            </v-row>
        </v-app>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import vue from 'vue';
import AbbiButton from '@/components/input/AbbiButton.vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import Loader from '@/views/Loader.vue';

import DataPointModal from '@/components/modals/DataPointModal.vue';
import ChatApi from '@/apis/chat.api';
import ScriptModel from '@/models/script.model';

import ScriptIOService from '@/services/io/script.io.service';

export default vue.extend({
    name: 'SettingsModal',
    components: {AbbiButton, AbbiTextInput, Loader},
    props: {
        options: Object,
    },
    watch: {
        publicName(val: string): void {
            this.$emit('publicNameChanged', val);
        },
    },
    data() {
        return {
            color: {
                blue: 'hsl(184, 100%, 31%)',
                green: 'hsl(70, 57%, 48%)',
            },
            dataPoints: [],
            connections: [],
            errorMessages: [],
            loading: false,
            publicName: '',
            languages: {
                nl: 'Nederlands',
                en: 'Engels',
                fr: 'Frans',
                de: 'Duits',
                it: 'Italiaans',
                es: 'Spaans',
                ar: 'Arabisch',
                ru: 'Russisch',
            },
        };
    },
    mounted() {
        this.publicName = this.options.publicName;
    },
    methods: {
        openDataPointsModal() {
            this.$store.commit('setModal', {
                title: 'External data',
                component: DataPointModal,
                options: {
                    modalStyle: {
                        width: '72%',
                    },
                    modalHeaderStyle: {
                        marginLeft: '43% !important',
                    },
                },
            });
        },
        exportScript() {
            const {scriptUuid} = this.$route.params;
            const {communitySlug} = this.$router.currentRoute.params;
            ChatApi.readChat(communitySlug, scriptUuid)
                .then(async (responseData) => {
                    if (responseData !== undefined) {
                        const script = responseData as ScriptModel | null;

                        if (script !== null) {
                            await ScriptIOService.exportToFile(script.instructions, `script.${script.name}.${communitySlug}`);
                        }
                    }
                });
        },
        languageChanged() {
            this.$emit('languageChanged', this.$refs.languages.value);
        },
    },
});
</script>

<style lang="scss">
    @import 'src/styles/colors';
    .settings-modal {
        padding: 1em;
        display: flex;
        flex-direction: row;

        button {
            margin-right: 1em;
        }

        .value-source {
            margin-left: 9px;
            width: 27%;
        }
        .data-selector {
            margin-left: 9px;
            width: 27%;
        }
        .add-data-point {
            background-color: $abbi-green;
            margin-left: 50px;
            border-radius: 6px;
            color: white;
        }

        .remove-data-point {
            background-color: $abbi-red;
            border-radius: 6px;
            margin-left: 13px;
            color: white;
        }

        .submit-dara-modal {
            padding-bottom: 12px;
        }
    }
</style>
