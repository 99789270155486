import InstructionModel from '@/models/instruction.model';
import {STATUS_CLOSED, STATUS_LIVE} from '@/helpers/constants';

export default class ScriptModel {
    uuid!: string;

    name = '';

    public_name?: string = '';

    instructions: InstructionModel[] | null = null

    status?: number = undefined;

    language?: string = 'nl';

    queue_config?: {
        transport?: string;
    };
}
