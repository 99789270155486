<template>
    <div id="chat-management-page" v-if="script !== null">
        <div id="overview" class="d-flex flex-column">
            <div class="header">
                <div class="title">
                    <AbbiTextInput :readonly="scriptLive" name="script-title" v-model="script.name" :placeholder="$t('FIELDS.TITLE')" />
                </div>
                <div class="options">
                    <AbbiButton is-blue @click="openChatSettingsModal">
                        <FaIcon icon="cog" /> Options
                    </AbbiButton>
                </div>
            </div>
            <div ref="outline" id="script-outline" class="main flex-1">
                <div class="main-wrapper p-0">
                    <draggable
                        v-if="script.instructions.length > 0"
                        :value="script.instructions"
                        @change="instructionListChanged"
                        :disabled="scriptLive && !liveEditable"
                    >
                        <template v-for="(instruction, index) in script.instructions">
                            <div :key="'instr-'+index"
                                 @click="currentInstruction === instruction ? currentInstruction = null : currentInstruction = instruction"
                                 class="instruction" :class="{
                                    end: instruction.type === 'end',
                                    active: instruction === currentInstruction,
                                }">
                                <div class="side-info">
                                    <FaIcon v-if="isInstructionIconVector(instruction)" :icon="instructionIcon(instruction)" />
                                    <img v-else :src="`/img/icons/instructions/${instructionIcon(instruction)}.png`" :alt="`image-${badgeContent(instruction)}`">

                                    <span>Q{{ index + 1 }}</span>
                                </div>
                                <FaIcon v-if="instruction.type === 'start'" icon="door-open" />
                                <div class="main-info">
                                    <div v-if="devMode">
                                        <code>i: {{instruction.uuid }}</code>
                                        <code class="ml-2" v-if="instruction.question">q: {{ instruction.question.uuid }}</code>
                                    </div>
                                    <div v-if="instruction.draft">
                                        <span class="badge draft">DRAFT</span>
                                    </div>
                                    <h3 class="content flex-1" v-html="textForInstruction(instruction)"></h3>
                                    <p class="condition" v-if="instruction.condition">{{ instruction.condition }}</p>
                                    <div class="meta">
                                        <span class="badge">{{ badgeContent(instruction) }}</span>
                                        <span class="badge shortLabel" v-if="instruction.label || (instruction.type === 'action' && instruction.action.type === 'send2api' && labelBadgeContent(instruction) !== '')">
                                            {{ labelBadgeContent(instruction) }}
                                        </span>
                                        <span class="iconWrapper">
                                            <FaIcon class="iconMargin" icon="copy" :disabled="scriptLive" @click.stop="copy(instruction)"/>
                                            <FaIcon class="iconMargin" icon="arrow-up" :disabled="scriptLive" @click.stop="moveUp(instruction.order)"/>
                                            <FaIcon class="iconMargin" icon="arrow-down" :disabled="scriptLive" @click.stop="moveDown(instruction.order)" />
                                            <FaIcon class="iconMargin" icon="trash" @click.stop="removeInstruction(instruction.order, instruction.uuid)" />
                                        </span>
                                    </div>
                                </div>
                                <div v-if="instruction.type === 'end'" class="side-info end">

                                </div>
                            </div>
                        </template>
                    </draggable>
                    <div v-else>
                        <AbbiButton  is-blue @click="importScriptFromFile">
                            <FaIcon icon="file-import" /> Import Script from file
                        </AbbiButton>
                        <Spinner v-if="isImporting" label="Importing Script..." />
                    </div>
                </div>
            </div>
            <div id="overview-footer" class="footer flex-row">
                <AbbiButton class="flex-1" :disabled="!canSave" grow @click="saveChat">{{ $t('ACTION.CHAT.SAVE') }}</AbbiButton>
                <AbbiButton class="flex-1 ml-1" is-red grow @click="backToOverview">Close without saving</AbbiButton>
            </div>
        </div>
        <div id="editor" class="d-flex flex-column p-3 w-100">
<!--            <div class="header">-->
<!--                <div class="title">-->
<!--                    <AbbiTextInput :readonly="scriptLive" name="script-title" v-model="script.name" :placeholder="$t('FIELDS.TITLE')" />-->
<!--                </div>-->
<!--                <div class="options">-->
<!--                    <AbbiButton is-blue @click="openChatSettingsModal">-->
<!--                        <FaIcon icon="cog" /> Options-->
<!--                    </AbbiButton>-->
<!--                </div>-->
<!--            </div>-->
            <div class="main flex-1">
                <div class="main-wrapper p-0">
                    <InstructionEditor
                        v-if="currentInstruction" :script-live="scriptLive" :live-editable="liveEditable"
                        :allInstructionNames="allInstructionNames"
                        :instruction="currentInstruction" :uploads="fileUploads" @save="currentInstruction = null"
                        @addUpload="fileUploads.push($event)" :script="script" />
                    <InstructionSelection v-else-if="!scriptLive" @newInstruction="addInstruction" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
import Vue from 'vue';
import ScriptModel from '@/models/script.model';
import ChatApi from '@/apis/chat.api';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import {NavigationGuardNext, Route} from 'vue-router';
import InstructionEditor from '@/components/chat/InstructionEditor.vue';
import InstructionModel from '@/models/instruction.model';
import AbbiButton from '@/components/input/AbbiButton.vue';
import InstructionSelection from '@/components/chat/InstructionSelection.vue';
import CommunityApi from '@/apis/community.api';
import draggable from 'vuedraggable';
import {v4 as uuidv4} from 'uuid';
import MessageModel from '@/models/message.model';
import ChoiceModel from '@/models/choice.model';
import {STATUS_CLOSED, STATUS_LIVE} from '@/helpers/constants';

import SettingsModal from '@/components/modals/SettingsModal.vue';
import ScriptIOService, {IOStatus} from '@/services/io/script.io.service';
import Spinner from '@/components/bootstrap/Spinner.vue';
import BootstrapVue, {VBToggle} from 'bootstrap-vue';

export default Vue.extend({
    name: 'ChatEditor',
    directives: {
        VBToggle,
    },
    components: {
        Spinner, AbbiTextInput, AbbiButton, InstructionEditor, InstructionSelection, draggable,
    },
    data() {
        return {
            script: null as ScriptModel | null,
            currentInstruction: null as InstructionModel | null,
            markedForDelete: [] as string[],
            fileUploads: [] as Record<string, File>[],
            isImporting: false as boolean,
        };
    },
    computed: {
        devMode(): boolean {
            return this.$route.query.dev == 'true';
        },
        community() {
            return this.$store.getters.community;
        },
        canSave(): boolean {
            if (this.script === null || this.script.instructions === null) return false;
            return this.script!.instructions!.filter((instr) => instr.type === 'empty').length === 0;
        },
        scriptLive(): boolean {
            if (this.script?.status === undefined) return false;
            return [STATUS_LIVE, STATUS_CLOSED].includes(this.script?.status);
        },
        liveEditable(): boolean {
            return this.$route.query.allowChanges === null;
        },
        allInstructionNames(): string[] {
            const instructions = this.script?.instructions ?? null;

            if (instructions === null) return [];

            return (
                instructions
                    ?.filter((i): i is InstructionModel => i.label !== undefined && i.label !== null && i.label !== '')
                    .map((i) => i.label!) ?? []
            );
        },
    },
    methods: {
        showAddInstructionBtn($event: MouseEvent) {
            const {outline} = this.$refs as Record<string, Element>;
            const children = outline.getElementsByClassName('instruction');
            for (let i = 0; i < children.length; i += 1) {
                const currentChild = children[i];
                const clientRect = currentChild.getBoundingClientRect();
                if (clientRect.top > $event.clientY) {
                    break;
                }
            }
        },
        openChatSettingsModal() {
            this.$store.commit('setModal', {
                title: 'Chat Options',
                component: SettingsModal,
                options: {
                    modalStyle: {
                        width: 'auto',
                    },
                    modalHeaderStyle: {
                        marginLeft: 'auto',
                    },
                    language: this.script?.language ?? 'nl',
                    publicName: this.script?.public_name ?? this.script?.name ?? 'Chat',
                },
                eventHandlers: {
                    languageChanged: (languageCode: string) => {
                        if (this.script) {
                            this.script.language = languageCode;
                        }
                    },
                    publicNameChanged: (name: string) => {
                        if (this.script) {
                            this.script.public_name = name;
                        }
                    },
                },
            });
        },
        addInstruction(instr: InstructionModel) {
            // eslint-disable-next-line no-param-reassign
            instr.order = this.script?.instructions?.length || 0;

            // if (instr.type === 'question') {
            //     // eslint-disable-next-line no-param-reassign
            //     // instr.label = `q${instr.order + 1}`;
            // }

            if (this.script !== null) {
                if (this.script.instructions === null) this.script.instructions = [];
                this.script.instructions.push(instr);
                this.currentInstruction = instr;
            }
        },
        instructionListChanged($event: CustomEvent & Record<string, any>) {
            this.reOrderInstructionList($event.moved.oldIndex, $event.moved.newIndex);
        },
        reOrderInstructionList(oldIdx: number, newIdx: number) {
            const [current] = (this.script?.instructions ?? []).filter(
                (instr: InstructionModel) => instr.order === oldIdx,
            );
            this.currentInstruction = current;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS2339
            if (newIdx < 0 || newIdx > (this.script?.instructions?.length ?? 0)) {
                return;
            }
            const instructions = this.script!.instructions || [] as InstructionModel[];
            instructions.splice(newIdx, 0, instructions.splice(oldIdx, 1)[0]);
            this.script!.instructions = this.reOrderInstructions(instructions);
        },
        removeInstruction(order: number, uuid: string) {
            if (this.script === null || this.script.instructions === null) return;
            this.script.instructions = this.script.instructions.filter((instr: InstructionModel) => instr.order !== order);
            if (!this.currentInstruction || Number(this.currentInstruction.order) === Number(order)) {
                this.currentInstruction = null;
            }
            this.script!.instructions = this.reOrderInstructions(this.script!.instructions);
            if (uuid !== null) {
                this.markedForDelete.push(uuid);
            }
        },
        moveUp(order: number) {
            this.reOrderInstructionList(order, order - 1);
        },
        moveDown(order: number) {
            this.reOrderInstructionList(order, order + 1);
        },
        reOrderInstructions(oldInstructions: InstructionModel[]) {
            const instructions = [] as InstructionModel[];
            const reordered = [] as InstructionModel[];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            for (let i = 0; i < oldInstructions.length; i++) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const instr = oldInstructions[i];
                instr.order = i;
                reordered.push(instr);
            }
            reordered.sort((instrA: InstructionModel, instrB: InstructionModel) => instrA.order - instrB.order);
            instructions.push(...reordered);
            return instructions;
        },
        copy(instr: InstructionModel) {
            const newInstr = JSON.parse(JSON.stringify(instr));
            newInstr.uuid = uuidv4();
            newInstr.label = null;
            if (newInstr.initialAction) {
                newInstr.initialAction.uuid = uuidv4();
            }

            if (newInstr.action) {
                newInstr.action.uuid = uuidv4();
            }

            if (newInstr.messages) {
                newInstr.messages.map((msg: MessageModel) => {
                    // eslint-disable-next-line no-param-reassign
                    msg.uuid = uuidv4();
                    return msg;
                });
            }

            if (newInstr.question) {
                newInstr.question.uuid = uuidv4();
                if (['multiple', 'choice'].includes(newInstr.question.type)) {
                    newInstr.question.choices.map((choice: ChoiceModel) => {
                        // eslint-disable-next-line no-param-reassign
                        choice.uuid = uuidv4();
                        return choice;
                    });
                }
            }
            newInstr.order = instr.order + 1;
            const instructions = this.script!.instructions || [] as InstructionModel[];
            instructions.splice(newInstr.order, 0, newInstr);
            this.script!.instructions = this.reOrderInstructions(instructions);
            this.currentInstruction = newInstr;
        },

        async saveChat(goBack = true) {
            if (this.script === null) return;
            if (!this.script.instructions) return;

            let currentSize = 0;
            let formData = new FormData();
            const imgUrls = [] as Promise<Record<string, any>>[];
            for (let i = 0; i < this.script.instructions.length; i++) {
                this.script.instructions[i].order = i;

                const instr = this.script.instructions[i];
                if (instr.type === 'image') {
                    if (this.fileUploads.length > 0) {
                        const img = (this.fileUploads.filter((imgEntry) => {
                            const [key] = Object.entries(imgEntry)[0];

                            return key === instr.uuid;
                        }) || [null]).shift();

                        if (img !== null && img !== undefined) {
                            const file = img[instr.uuid] as File;

                            if (currentSize !== 0 && currentSize + file.size > 1024 * 1024 * 10) {
                                imgUrls.push(CommunityApi.uploadImage(this.community.uuid, formData));
                                currentSize = 0;
                                formData = new FormData();
                            }
                            currentSize += file.size;
                            formData.append(`${(new Date()).getTime()}_${instr.uuid}`, file);
                        }
                    }
                }
            }
            const finalSubmit = [...formData.keys()].length > 0;

            if (finalSubmit) imgUrls.push(CommunityApi.uploadImage(this.community.uuid, formData));
            const imageUrls = await Promise.allSettled(imgUrls);

            if (imageUrls.length > 0) {
                for (let i = 0; i < this.script.instructions.length; i++) {
                    const instr = this.script.instructions[i];
                    if (instr.type === 'image') {
                        // first we need to resolve the image out of the promise array. This looks like:
                        /*
                        [
                            {
                                status: 'bla',
                                value: {
                                    {timestamp}_{instr1UUID}: {url},
                                    {timestamp}_{instr2UUID}: {url},
                                },
                            }
                            {
                                status: 'bla',
                                value: {
                                    {timestamp}_{instr3UUID}: {url},
                                    {timestamp}_{instr4UUID}: {url},
                                    {timestamp}_{instr5UUID}: {url},
                                },
                            }
                        ]
                         */
                        let key;
                        const imgEntry = imageUrls.filter((entry) => {
                            if (entry.status === 'fulfilled') {
                                const imgKey = Object.keys(entry.value).find((value) => value.indexOf(instr.uuid) >= 0);
                                if (imgKey) {
                                    key = imgKey;
                                    return true;
                                }
                            }
                            return false;
                        });

                        // then if we have found the entry, we need to insert the URL into the msg
                        if (imgEntry.length > 0) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore TS2339
                            const img = imgEntry[0]!.value;
                            for (let m = 0; m < instr.messages!.length; m++) {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore TS2538
                                const imgMsg = this.script.instructions[i]!.messages[m]!.content;

                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore TS2538
                                this.script.instructions[i]!.messages[m]!.content = imgMsg.replace('{imageSource}', img[key]);
                            }
                        }
                    }
                }
            }

            /* eslint-disable @typescript-eslint/naming-convention */
            if (this.script.queue_config !== undefined) {
                delete this.script.queue_config;
            }
            /* eslint-enable @typescript-eslint/naming-convention */

            const updateChatResponse = await ChatApi.updateChat(
                this.community.slug,
                this.script.uuid,
                {
                    script: this.script,
                    markedForDelete: this.markedForDelete,
                },
            );
            if (updateChatResponse !== undefined && goBack) {
                this.backToOverview();
            }
        },
        backToOverview() {
            this.$router.push({name: 'ChatDetails', params: {communitySlug: this.community.slug, script: this.script!.uuid}});
        },
        textForInstruction(instr: InstructionModel) {
            if (instr.type === 'end') {
                return 'End chat';// this.$t('GENERAL.END_CHAT');
            }

            if (instr.type === 'action' && instr.action) {
                return '';
            }

            if (instr.type === 'image' && instr.messages !== null) {
                return instr.messages[0].content.replace(/(<img.*?\/>)/g, '');
            }

            if (instr.messages !== null && instr.messages.length !== 0) {
                return instr.messages[0].content;
            }

            return this.$t('GENERAL.NEW_INSTRUCTION');
        },
        isInstructionIconVector(instr: InstructionModel): boolean {
            if (instr.type !== 'question') {
                return false;
            }

            if ((instr.question?.presentation?.type || instr.question?.type) !== 'starRating') {
                return false;
            }

            return true;
        },
        instructionIcon(instr: InstructionModel): string {
            let content = '';

            switch (instr.type) {
                case 'question':
                    const type = instr.question?.presentation?.type || instr.question?.type || instr.type;

                    switch (type) {
                        case 'starRating':
                            return 'star-half-stroke';
                        default:
                            return type;
                    }
                // content += instr.question?.type || instr.type;

                // if (instr.question?.presentation?.type) {
                //     content += ` (${instr.question.presentation.type})`;
                // }
                // break;

                case 'action':
                    content += instr.action?.type || instr.type;
                    break;

                default:
                    content += instr.type;
                    break;
            }

            return content;
        },
        badgeContent(instr: InstructionModel): string {
            let content = '';
            const action = instr.action ?? null;

            switch (instr.type) {
                case 'question':
                    return instr.question?.presentation?.type || instr.question?.type || instr.type;
                // content += instr.question?.type || instr.type;

                // if (instr.question?.presentation?.type) {
                //     content += ` (${instr.question.presentation.type})`;
                // }
                // break;

                case 'action':
                    if (!action) return '';

                    content += action.type || instr.type;

                    if (action.type === 'send2api') {
                        content = `${content} (v${action.params?.version ?? 1})`;
                    }
                    break;

                default:
                    content += instr.type;
                    break;
            }

            return content;
        },
        labelBadgeContent(instr: InstructionModel): string {
            switch (instr.type) {
                case 'action':
                    const action = instr.action ?? null;

                    if (!action) return '';

                    if (action.type === 'send2api') {
                        if (action.params?.labels?.name) {
                            return action.params?.labels?.name;
                        }
                    }
                default:
                    return instr.label ?? '';
            }
        },
        async importScriptFromFile(): Promise<boolean> {
            this.script!.instructions = await ScriptIOService.importFromFile((status: IOStatus) => {
                switch (status) {
                    case IOStatus.STARTED:
                        this.isImporting = true;
                        break;
                    case IOStatus.COMPLETE:
                        this.isImporting = false;
                        break;
                    default:
                        break;
                }
            });

            this.saveChat(false);

            return true;
        },
        labelQuestions() {
            const questionsWithoutName: InstructionModel[] = this.script?.instructions
                ?.filter((i) => (i.type === 'question' && (i.label === undefined || i.label === ''))) ?? [];

            if (questionsWithoutName.length === 0) {
                return;
            }

            if (this.script === null) return;

            questionsWithoutName.forEach(function (this: InstructionModel[], i) {
                this[i.order].label = `q${i.order + 1}`;
            }, this.script?.instructions);
        },
    },
    created() {
        const {scriptUuid} = this.$router.currentRoute.params;
        ChatApi.readChat(this.$router.currentRoute.params.communitySlug, scriptUuid)
            .then((responseData) => {
                if (responseData !== undefined) {
                    this.script = responseData as ScriptModel | null;
                    // this.labelQuestions();
                }
            });
    },
    beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
        next({path: `/${to.params.communitySlug}/chats`});
    },
});
</script>

<style scoped lang="scss">
@import 'src/styles/colors';

#editor {
    max-width: none;
}

.header {
    display: flex;
    .title {
        flex: 70%;
        .abbi-text-input {
            height: 44px;
        }
    }
    .options {
        padding-left: 16px;
        .abbi-button {
            height: 43px;
        }
    }
}

.main {
    position: relative;
    padding: 1rem;
    width: 100%;

    .main-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        padding: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.footer {
    z-index: 1;

    margin: -1rem;
    margin-top: 0;
    padding: 1rem;
}
</style>

<style lang="scss">
@import 'src/styles/colors';

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';

@import '~bootstrap/scss/root';
@import "~bootstrap/scss/grid";
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/card';

@import "~bootstrap-vue/src/index.scss";

#chat-management-page {
    display: flex;

    #overview {
        box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.2);

        width: 33.4vw;
        max-width: 500px;
        padding: 1rem;
        background-color: transparentize(lighten($abbi-blue, 50), 0.9); //rgb(250, 250, 250);
        overflow: hidden;

        .chat-management-header {
            display: flex;
            .chat-management-title {
                flex: 70%;
                .abbi-text-input {
                    height: 44px;
                }
            }
            .chat-management-options {
                padding-left: 16px;
                .abbi-button {
                    height: 43px;
                }
            }
        }

        #script-outline {
            position: relative;
            margin-top: 1rem;
            padding: 1rem 0;

            .main-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                overflow-y: auto;

                .instruction {
                    display: flex;

                    cursor: pointer;
                    border: 2px solid rgba(0, 0, 0, 0.25);
                    border-radius: 4px;

                    overflow: hidden;

                    margin: 1rem 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    background-color: white;

                    &.active {
                        background-color: $abbi-green;
                        color: white;
                    }

                    .side-info {
                        display: flex;
                        flex-direction: column;
                        padding: 4px;

                        > svg.svg-inline--fa {
                            max-width: 2em;
                            max-height: 2em;
                            aspect-ratio: 1;
                            opacity: 0.2;
                        }

                        > img {
                            max-width: 2em;
                            max-height: 2em;
                            opacity: 0.2;
                        }

                        > * {
                            display: block;
                            flex: 1;
                            height: 50%;

                            align-self: center;
                            vertical-align: middle;
                        }

                        > span {
                            display: flex;
                            margin: 0.25em;
                            align-items: center;
                        }

                        > .abbi-button > button {
                            border-radius: 0;
                            border-top-right-radius: 6px;
                        }
                    }

                    .main-info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        padding: 0.5rem 1rem;

                        .content {
                            font-weight: normal;
                            font-size: 16px;
                            p {
                                margin-top: 6px;
                            }
                        }
                        .condition {
                            font-size: 14px;
                            color: #5b6b6d;
                            padding-bottom: 5px;
                            font-family: monospace;
                        }
                        > p {
                            margin: 0.25rem;
                        }

                        .meta {
                            .badge {
                                display: inline-block;
                                width: max-content;

                                padding: 0.25rem 0.5rem;
                                margin-bottom: 0.25rem;
                                background-color: $abbi-blue;

                                color: white;

                                border-radius: 6px;

                                margin-left: 0.25rem;

                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                            .iconWrapper {
                                white-space: nowrap;
                                float: right;
                                margin-top: 5px;
                            }
                            .shortLabel {
                                background: #DCA179;
                            }
                            .iconMargin {
                                margin: 0 6px 0 6px;
                            }
                            svg[data-icon='trash'] {
                                color: $abbi-red;
                            }
                            svg[data-icon='arrow-down'], svg[data-icon='arrow-up'] {
                                color: $abbi-blue;
                            }
                            svg[data-icon='copy'] {
                                color: #7a7a7a;
                            }
                        }
                    }

                    &.end {
                        // cursor: default;

                        .side-info.end {
                            color: $abbi-red;
                            font-size: 2em;
                            padding: 0.5rem;
                        }
                    }
                }
            }

            .add-instruction {
                padding: 1rem 0;
                margin: -1rem 0;
            }
        }

        #overview-footer {
            z-index: 1;

            margin: -1rem;
            margin-top: 0;
            padding: 1rem;
        }
    }

    #editor {
        position: relative;
        overflow-x: hidden;
    }
}
</style>
